import { defineMessages } from "react-intl";

export const NO_CASE_SCAN_FOR_PALLET_LABELS = defineMessages({
  title: {
    id: "crossdock.noCaseScanForPalletModal.title",
    defaultMessage: "Empty Pallet detected",
  },
  message: {
    id: "crossdock.noCaseScanForPalletModal.message",
    defaultMessage: `No cases were unloaded after starting processing for the previous pallet {lastOpenedPalletId} at {lastOpenedPalletAt}.`,
  },
});
