import React from "react";
import { ModalTemplate, DoneButton, dangerModalIcon } from "@deliverr/ui-facility";
import { useCrossdockModal } from "crossdock/common/modal";
import { useIntl } from "react-intl";
import { NO_CASE_SCAN_FOR_PALLET_LABELS } from "./NoCaseScanForPalletModal.labels";

interface NoCaseScanForPalletModalProps {
  lastOpenedPalletId: string;
  lastOpenedPalletAt: Date;
}

export const NoCaseScanForPalletModal: React.FC<NoCaseScanForPalletModalProps> = ({
  lastOpenedPalletId,
  lastOpenedPalletAt,
}) => {
  const { hideAllModals } = useCrossdockModal();
  const { formatMessage } = useIntl();

  return (
    <ModalTemplate
      title={formatMessage(NO_CASE_SCAN_FOR_PALLET_LABELS.title)}
      text={formatMessage(NO_CASE_SCAN_FOR_PALLET_LABELS.message, {
        lastOpenedPalletId,
        lastOpenedPalletAt,
      })}
      iconProps={dangerModalIcon}
    >
      <DoneButton onClick={hideAllModals} />
    </ModalTemplate>
  );
};
